import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLang } from '../../messages';

interface LocaleState {
  lang: SupportedLang;
}

const initialState: LocaleState = { lang: SupportedLang.EN };

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<SupportedLang>) => {
      state.lang = action.payload;
    }
  }
});

export const { setLocale } = localeSlice.actions;
export default localeSlice.reducer;
