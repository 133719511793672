import { HttpClient } from '../common/httpClient';
import {
  WhatsappDailyDashboardOverview,
  WhatsappDashboardData,
  WhatsappMonthlyDashboardOverview,
  WhatsappBalanceDayData,
  WhatsappBalanceDayDataStructure,
  WhatsappBalanceOverview,
  WhatsappAdminMonthlyBalanceOverview,
} from '../../types/meta/whatsappStatistics';

export type WhatsappDailyBalanceOverviewResponse = {
  readonly messagingStatistics: {
    [day: string]: WhatsappDashboardData;
  }
};

export type WhatsappMonthlyBalanceOverviewResponse = {
  readonly messagingStatistics: {
    [month: string]: WhatsappDashboardData;
  }
};

export interface WhatsappDailyBalanceResponse {
  readonly dailyStats: { [date: string]: WhatsappBalanceDayData };
};

export type WhatsappBalanceOverviewResponse = {
  readonly allConversationCount: number;
  readonly authenticationConversationCount: number;
  readonly balance: number;
  readonly dailyStats: { [key: string]: number };
  readonly marketingConversationCount: number;
  readonly revenue: number;
  readonly totalUsage: number;
  readonly serviceConversationCount: number;
  readonly utilityConversationCount: number;
};

export type WhatsappAdminMonthlyBalanceOverviewResponse = {
  readonly monthlyCount: { [key: number]: number };
};

export class WhatsappStatisticsApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToWhatsappDailyDashboard(response: WhatsappDailyBalanceOverviewResponse): WhatsappDailyDashboardOverview {
    const mappedStatistics: WhatsappDailyDashboardOverview = {};

    Object.keys(response.messagingStatistics).forEach(dayStr => {
      const day = Number(dayStr);

      if (!isNaN(day)) {
        mappedStatistics[day] = response.messagingStatistics[dayStr];
      }
    });

    return mappedStatistics;
  }

  private mapToWhatsappMonthlyDashboard(response: WhatsappMonthlyBalanceOverviewResponse): WhatsappMonthlyDashboardOverview {
    return response.messagingStatistics;
  }

  private mapToWhatsappDailyBalance(response: WhatsappDailyBalanceResponse, from: string): WhatsappBalanceDayDataStructure {
    const start = new Date(from).toLocaleDateString('en-CA');
    const filteredDailyStats = Object.keys(response.dailyStats || {})
      .filter((date) => date >= start)
      .reduce((acc, date) => {
        acc[date] = response.dailyStats![date];
        return acc;
      }, {} as Record<string, any>);
  
    return {
      dailyStats: filteredDailyStats,
    };
  }

  private mapToWhatsappBalanceOverview(response: WhatsappBalanceOverviewResponse): WhatsappBalanceOverview {
    return {
      allConversationCount: response.allConversationCount,
      authenticationConversationCount: response.authenticationConversationCount,
      balance: response.balance,
      dailyStats: response.dailyStats,
      marketingConversationCount: response.marketingConversationCount,
      revenue: response.revenue,
      totalUsage: response.totalUsage,
      serviceConversationCount: response.serviceConversationCount,
      utilityConversationCount: response.utilityConversationCount,
    };
  }

  private mapToWhatsappMonthlyBalanceOverview(response: WhatsappAdminMonthlyBalanceOverviewResponse): WhatsappAdminMonthlyBalanceOverview {
    return {
      monthlyCount: response.monthlyCount,
    };
  }

  getUserDailyStats(businessAccountId: string): Promise<WhatsappDailyDashboardOverview> {
    return this.httpClient.get<WhatsappDailyBalanceOverviewResponse>(`statistics/month/current?businessAccountId=${businessAccountId}`)
      .then(data => this.mapToWhatsappDailyDashboard(data));
  }

  getUserMonthlyStats(businessAccountId: string): Promise<WhatsappMonthlyDashboardOverview> {
    return this.httpClient.get<WhatsappMonthlyBalanceOverviewResponse>(`statistics/year/current?businessAccountId=${businessAccountId}`)
      .then(data => this.mapToWhatsappMonthlyDashboard(data));
  }

  getUserDailyBalanceOverview(id: string, from: string, to: string): Promise<WhatsappBalanceDayDataStructure> {
    return this.httpClient.get<WhatsappDailyBalanceResponse>(`${id}/statistics/user-balance-overview?from=${from}&to=${to}`)
      .then(data => this.mapToWhatsappDailyBalance(data, from));
  }

  getAdminBalanceOverview(from: string, to: string, appId: string): Promise<WhatsappBalanceOverview> {
    return this.httpClient.get<WhatsappBalanceOverviewResponse>(`statistics/user-balance-overview?from=${from}&to=${to}&appId=${appId}`)
      .then(data => this.mapToWhatsappBalanceOverview(data));
  }

  getAdminMonthlyStats(year: number, appId: string): Promise<WhatsappAdminMonthlyBalanceOverview> {
    return this.httpClient.get<WhatsappAdminMonthlyBalanceOverviewResponse>(`statistics/user-monthly-count?year=${year}&appId=${appId}`)
      .then(data => this.mapToWhatsappMonthlyBalanceOverview(data));
  }

}
