import { createTheme } from '@mui/material/styles';
import { AppTheme } from './types';

const gms = createTheme({
  palette: {
    primary: {
      light: '#def4ea',
      main: '#00C072',
      //dark: '#004e65',
      contrastText: '#ffffff',
    },
    secondary: {
      //light: '#894494',
      main: '#00473b',
      //dark: '#2e003b',
      contrastText: '#fff',
    },
    type: 'light',
    custom: {
      appBarColor: '#00C072',
    },
    online: {
      main: '#4caf50',
    },
    away: {
      main: '#ffd700',
    },
    offline: {
      main: '#d32f2f',
    },
    unknown: {
      main: '#a9a9a9',
    },
    infobox: {
      color: {
        orange: '#838FF7',
        red: '#C3E4FD',
        blue: '#48A1F8',
        green: '#00C072',
        grey: '#D1D2FB',
        viber: '#7360F2',
        whatsapp: '#25d366',
        sms: '#97bbcd',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#0000004d',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 16,
            border: '2px solid #fff',
            backgroundColor: '#0000004d',
          },
        },
      },
    },
  },
});

/** NEW UI theme */

// Define the typography structure
const typography = {
  display1: {
    fontSize: '56px',
    fontWeight: 700, // bold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  display2: {
    fontSize: '56px',
    fontWeight: 700, // bold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h1: {
    fontSize: '40px',
    fontWeight: 600, // semibold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h2: {
    fontSize: '32px',
    fontWeight: 700, // bold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h3: {
    fontSize: '28px',
    fontWeight: 700, // bold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h4: {
    fontSize: '24px',
    fontWeight: 700, // bold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h5: {
    fontSize: '20px',
    fontWeight: 600, // semibold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  h6: {
    fontSize: '16px',
    fontWeight: 600, // semibold
    lineHeight: 1.2,
    marginBottom: '0.5em',
  },
  lead: {
    fontSize: '20px',
    fontWeight: 400, // regular
    lineHeight: 1.5,
    marginBottom: '1em',
  },
  body: {
    fontSize: '16px',
    fontWeight: 400, // regular
    lineHeight: 1.5,
    marginBottom: '1em',
  },
  bodyMedium: {
    fontSize: '16px',
    fontWeight: 600, // semibold
    lineHeight: 1.5,
    marginBottom: '1em',
  },
};

export const gmsThemeLight: AppTheme = {
  name: 'gms-theme-dark',
  colors: {
    primary: {
      normal: '#00C072',
      medium: '#00A361',
      dark: '#008650',
    },
    secondary: {
      normal: '#E6F8F1',
      medium: '#C2F0DD',
      dark: '#99E6C7',
    },
    background: {
      light: '#F8F8F7',
      medium: '#F2F2F2',
      dark: '#E8E8E8',
    },
    technical: {
      black: '#040404',
      gray: {
        60: '#04040499',
        30: '#0404044D',
        15: '#04040426',
        5: '#0404040D',
      },
      white: '#FFFFFF',
    },
    status: {
      error: '#FF5959',
      warning: '#FF7801',
      info: '#00A4FF',
      highlight: {
        error: '#FFE5E5',
        warning: '#FFF9EB',
        info: '#E6F6FF',
      },
    },
    decorative: {
      forest: '#00DFAF',
      frost: '#C480FF',
      babyPink: '#FFB4F9',
      ultraViolet: '#808FFF',
      sea: '#80C6FF',
      barbiePink: '#FF80C6',
      honey: '#FFA641',
      leaf: '#80FFAC',
      indigo: '#004BA0',
      background: {
        forest: '#CCFFF4',
        frost: '#F3E5FF',
        babyPink: '#FFE5FD',
        ultraViolet: '#E5E9FF',
        sea: '#E5F4FF',
        barbiePink: '#FFE5F4',
        honey: '#FFF3E5',
        leaf: '#E5FFEE',
        indigo: '#E5F1FF',
      },
    },
  },
  typography,
};

export const gmsThemeDark: AppTheme = {
  name: 'gms-theme-dark',
  colors: {
    primary: {
      normal: '#00C072',
      medium: '#00D583',
      dark: '#00F098',
    },
    secondary: {
      normal: '#1A261F',
      medium: '#273830',
      dark: '#334A40',
    },
    background: {
      light: '#1A1A1A',
      medium: '#121212',
      dark: '#080808',
    },
    technical: {
      black: '#FFFFFF',
      gray: {
        60: '#FFFFFF99',
        30: '#FFFFFF4D',
        15: '#FFFFFF26',
        5: '#FFFFFF0D',
      },
      white: '#1A1A1A',
    },
    status: {
      error: '#FF7070',
      warning: '#FF9933',
      info: '#33B1FF',
      highlight: {
        error: '#3A2222',
        warning: '#3A2E1A',
        info: '#1A2A3A',
      },
    },
    decorative: {
      forest: '#00DFAF',
      frost: '#C480FF',
      babyPink: '#FFB4F9',
      ultraViolet: '#808FFF',
      sea: '#80C6FF',
      barbiePink: '#FF80C6',
      honey: '#FFA641',
      leaf: '#80FFAC',
      indigo: '#004BA0',
      background: {
        forest: '#1A2922',
        frost: '#291A33',
        babyPink: '#331A2E',
        ultraViolet: '#1A1C33',
        sea: '#1A2633',
        barbiePink: '#331A26',
        honey: '#33271A',
        leaf: '#1A331F',
        indigo: '#1A2333',
      },
    },
  },
  typography,
};

export const gmsThemes = {
  light: gmsThemeLight,
  dark: gmsThemeDark,
};

export default gms;
