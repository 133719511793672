import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router';
// @ts-expect-error Problem with incorrect types - open issue: https://github.com/elgerlambert/redux-localstorage/issues/78
import persistState from 'redux-localstorage';
import rootReducer, { AppState } from './reducers';

export const history = createBrowserHistory();

// Create enhancer for persistState
const persistStateEnhancer = persistState(['locale', 'routing', 'app', 'login', 'testAccount', 'appearance']);

const createMiddleware = (history: History<unknown>) => (getDefaultMiddleware: any) => {
  const middlewares = [routerMiddleware(history)];
  
  // Only add logger middleware in development
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    const { createLogger } = require('redux-logger');
    middlewares.push(createLogger({ collapsed: true, diff: true }));
  }

  return getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }).concat(middlewares);
};

export default function createStore(preloadedState?: Partial<AppState>) {
  const store = configureStore({
    reducer: rootReducer(history),
    preloadedState,
    middleware: createMiddleware(history),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(persistStateEnhancer);
    }
  });

  return store;
}