import { PhoneNumber, PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneNumberWithPlusSign } from '../types/phoneNumber';

const PLUS_SIGN: string = '+';

class PhoneNumberService {
  private readonly phoneNumberUtil: PhoneNumberUtil;

  constructor() {
    this.phoneNumberUtil = PhoneNumberUtil.getInstance();
  }

  toPhoneNumberWithPlusSign(number: string): PhoneNumberWithPlusSign {
    const mobilePhoneNumber = this.parseMobilePhoneNumber(number);

    return this.phoneNumberUtil.format(mobilePhoneNumber, PhoneNumberFormat.E164);
  }

  stripPlusSignFromPhoneNumber(number: string): string {
    const phoneNumberWithPlusSign = this.toPhoneNumberWithPlusSign(number);

    return phoneNumberWithPlusSign.substring(PLUS_SIGN.length);
  }

  isValidPhoneNumber(number: string): boolean {
    try {
      this.parseMobilePhoneNumber(number);
    } catch {
      return false;
    }
    return true;
  }

  private parseMobilePhoneNumber(number: string): PhoneNumber {
    const numberWithPlus = this.addMissingPlus(number);

    const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(numberWithPlus);
    const isValidMobilePhoneNumber = this.phoneNumberUtil.isValidNumber(phoneNumber)
      && (this.phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE ||
      this.phoneNumberUtil.getNumberType(phoneNumber) === PhoneNumberType.FIXED_LINE_OR_MOBILE);

    if (!isValidMobilePhoneNumber) {
      throw new Error('Invalid mobile phone number');
    }
    return phoneNumber;
  }

  private addMissingPlus(number: string): string {
    if (!number) {
      throw new Error('Phone number cannot be null, undefined or empty string');
    }
    return number.startsWith(PLUS_SIGN) ? number : `${PLUS_SIGN}${number}`;
  }
}

export default new PhoneNumberService();
