import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import stylesConfig from '../../config';
import { AppState } from '../../reducers';
import { setLocale } from '../../reducers/slices/locale';

const languages = stylesConfig.LANG?.split(',');

// @ts-expect-error TODO: Types of property 'direction' are incompatible.
const useStyles = makeStyles(() => ({
  languageSelection: {
    alignSelf: 'flex-end',
    justify: 'flex-start',
    direction: 'row-reverse',
    marginLeft: 'auto',
    width: 150,
    paddingRight: 20,
    paddingBottom: 10,
  },
  label: {
    left: '-14px',
    top: '6px',
  }
}));

const SelectLanguage = () => {
  const lang = useSelector((state: AppState) => state.locale.lang);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleChange = (event: $TSFixMe) => {
    dispatch(setLocale(event.target.value));
  };

  const menuLanguageItems = languages?.map((lang) => {
    const symbol = lang.trim().split('_')[0];
    const name = lang.trim().split('_')[1];
    return (
      <MenuItem value={symbol} key={symbol}>
        <FormattedMessage id={`login.${name}`} />
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.languageSelection}>
      <InputLabel htmlFor="languageSelect" className={classes.label}>
        <FormattedMessage id="common.label.language" />
      </InputLabel>
      <Select
        value={lang}
        variant="standard"
        onChange={handleChange}
        inputProps={{
          name: 'language',
          id: 'languageSelect',
        }}
      >
        {menuLanguageItems}
      </Select>
    </FormControl>
  );
}

export default SelectLanguage;
