const translatedCountriesOptions: { [key: string]: string } = {
  'Afghanistan': 'country.afghanistan',
  'Albania': 'country.albania',
  'Algeria': 'country.algeria',
  'Angola': 'country.angola',
  'Argentina': 'country.argentina',
  'Armenia': 'country.armenia',
  'Australia': 'country.australia',
  'Austria': 'country.austria',
  'Azerbaijan': 'country.azerbaijan',
  'Bahrain': 'country.bahrain',
  'Bangladesh': 'country.bangladesh',
  'Belarus': 'country.belarus',
  'Belgium': 'country.belgium',
  'Benin': 'country.benin',
  'Bolivia': 'country.bolivia',
  'Botswana': 'country.botswana',
  'Brazil': 'country.brazil',
  'Bulgaria': 'country.bulgaria',
  'Burkina Faso': 'country.burkina.faso',
  'Burundi': 'country.burundi',
  'Cambodia': 'country.cambodia',
  'Cameroon': 'country.cameroon',
  'Canada': 'country.canada',
  'Chad': 'country.chad',
  'Chile': 'country.chile',
  'China': 'country.china',
  'Colombia': 'country.colombia',
  'Costa Rica': 'country.costa.rica',
  'Croatia': 'country.croatia',
  'Czech Republic': 'country.czech.republic',
  'Denmark': 'country.denmark',
  'Dominican Republic': 'country.dominican.republic',
  'Ecuador': 'country.ecuador',
  'Egypt': 'country.egypt',
  'El Salvador': 'country.el.salvador',
  'Eritrea': 'country.eritrea',
  'Ethiopia': 'country.ethiopia',
  'Finland': 'country.finland',
  'France': 'country.france',
  'Gabon': 'country.gabon',
  'Gambia': 'country.gambia',
  'Georgia': 'country.georgia',
  'Germany': 'country.germany',
  'Ghana': 'country.ghana',
  'Greece': 'country.greece',
  'Guatemala': 'country.guatemala',
  'Guinea-Bissau': 'country.guinea.bissau',
  'Haiti': 'country.haiti',
  'Honduras': 'country.honduras',
  'Hong Kong': 'country.hong.kong',
  'Hungary': 'country.hungary',
  'India': 'country.india',
  'Indonesia': 'country.indonesia',
  'Iraq': 'country.iraq',
  'Ireland': 'country.ireland',
  'Israel': 'country.israel',
  'Italy': 'country.italy',
  'Ivory Coast': 'country.ivory.coast',
  'Jamaica': 'country.jamaica',
  'Japan': 'country.japan',
  'Jordan': 'country.jordan',
  'Kenya': 'country.kenya',
  'Kuwait': 'country.kuwait',
  'Laos': 'country.laos',
  'Latvia': 'country.latvia',
  'Lebanon': 'country.lebanon',
  'Lesotho': 'country.lesotho',
  'Liberia': 'country.liberia',
  'Libya': 'country.libya',
  'Lithuania': 'country.lithuania',
  'Madagascar': 'country.madagascar',
  'Malawi': 'country.malawi',
  'Malaysia': 'country.malaysia',
  'Mali': 'country.mali',
  'Mauritania': 'country.mauritania',
  'Mexico': 'country.mexico',
  'Moldova': 'country.moldova',
  'Mongolia': 'country.mongolia',
  'Morocco': 'country.morocco',
  'Mozambique': 'country.mozambique',
  'Namibia': 'country.namibia',
  'Nepal': 'country.nepal',
  'Netherlands': 'country.netherlands',
  'New Zealand': 'country.new.zealand',
  'Nicaragua': 'country.nicaragua',
  'Niger': 'country.niger',
  'Nigeria': 'country.nigeria',
  'North Macedonia': 'country.north.macedonia',
  'Norway': 'country.norway',
  'Oman': 'country.oman',
  'Pakistan': 'country.pakistan',
  'Panama': 'country.panama',
  'Papua New Guinea': 'country.papua.new.guinea',
  'Paraguay': 'country.paraguay',
  'Peru': 'country.peru',
  'Philippines': 'country.philippines',
  'Poland': 'country.poland',
  'Portugal': 'country.portugal',
  'Puerto Rico': 'country.puerto.rico',
  'Qatar': 'country.qatar',
  'Republic of the Congo (Brazzaville)': 'country.republic.of.the.congo.brazzaville',
  'Romania': 'country.romania',
  'Russia': 'country.russia',
  'Rwanda': 'country.rwanda',
  'Saudi Arabia': 'country.saudi.arabia',
  'Senegal': 'country.senegal',
  'Serbia': 'country.serbia',
  'Sierra Leone': 'country.sierra.leone',
  'Singapore': 'country.singapore',
  'Slovakia': 'country.slovakia',
  'Slovenia': 'country.slovenia',
  'Somalia': 'country.somalia',
  'South Africa': 'country.south.africa',
  'South Sudan': 'country.south.sudan',
  'Spain': 'country.spain',
  'Sri Lanka': 'country.sri.lanka',
  'Sudan': 'country.sudan',
  'Swaziland': 'country.swaziland',
  'Sweden': 'country.sweden',
  'Switzerland': 'country.switzerland',
  'Taiwan': 'country.taiwan',
  'Tajikistan': 'country.tajikistan',
  'Tanzania': 'country.tanzania',
  'Thailand': 'country.thailand',
  'Togo': 'country.togo',
  'Tunisia': 'country.tunisia',
  'Turkey': 'country.turkey',
  'Turkmenistan': 'country.turkmenistan',
  'Uganda': 'country.uganda',
  'Ukraine': 'country.ukraine',
  'United Arab Emirates': 'country.united.arab.emirates',
  'United Kingdom': 'country.united.kingdom',
  'USA': 'country.united.states',
  'Uruguay': 'country.uruguay',
  'Uzbekistan': 'country.uzbekistan',
  'Venezuela': 'country.venezuela',
  'Vietnam': 'country.vietnam',
  'Yemen': 'country.yemen',
  'Zambia': 'country.zambia',
  'Zimbabwe': 'country.zimbabwe'
};

export default translatedCountriesOptions;
